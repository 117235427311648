import styled from 'styled-components/macro'
import { Button, Space } from 'antd'

export const SpaceStyle = styled(Space)`
  width: 100%;
  max-width: 400px;
  padding: 15px;
  border-radius: 5px; 
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

  .ant-typography { // Убираем излишний margin для Paragraph от antd
    margin: 0px;
  }
`

export const RespondButtonStyle = styled(Button)`
  &, &:hover, &:focus {
    background: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
  }

  &:hover {
    opacity: 0.8
  }
`
