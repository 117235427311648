import { Row } from 'antd'
import styled from 'styled-components/macro'

export const PortalFooterStyle = styled(Row)`
  width: 100%;
  padding: 12px;
  margin-top: auto;
  
  background-color: ${({ theme }) => theme.primaryColor};
`
