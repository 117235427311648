import React, { useEffect, useRef, useState } from 'react'
import {
  Empty, Select, Space, Input,
} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { t } from 'i18next'

import { Vacancy } from './components'

const { Search } = Input

const VacanciesListWithFilter = ({ vacancies, companyButtonLabel }) => {
  const [availableCities, setAvailableCities] = useState([])
  const [cityFilter, setCityFilter] = useState(t('VacanciesListWithFilter.allCities'))
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const cityTitles = vacancies.map(({ cityTitle }) => cityTitle)
    const uniqueCities = cityTitles
      .filter(cityTitle => cityTitle !== null) // Убираем пустые cityTitle из вакансий с удаленной работой
      .filter((cityTitle, i, a) => a.indexOf(cityTitle) === i) // Оставляем только уникальные значения

    setAvailableCities(uniqueCities)
  }, [vacancies])

  const selectRef = useRef()

  // Переделать фильтрацию, когда сделаем, чтобы с бэка приходили айди городов и был поиск + пагинация
  const citySelectOptions = [t('VacanciesListWithFilter.allCities'), ...availableCities]
    .map(v => ({ value: v }))

  if (vacancies.find(({ isRemoteWork }) => isRemoteWork)) {
    citySelectOptions.splice(1, 0, { value: t('VacanciesListWithFilter.remoteWork') })
  }

  const vacanciesWithButtonLabel = vacancies.map((v) => {
    const vacancyCopy = { ...v }

    const vacancyButtonLabel = v.settings?.buttonLabel
    if (!vacancyButtonLabel) {
      vacancyCopy.settings = {
        ...v.settings,
        buttonLabel: companyButtonLabel ?? t('Vacancy.defaultButtonLabel'),
      }
    }

    return vacancyCopy
  })

  const getFilteredVacancies = () => {
    const filteredByCity = () => {
      switch (cityFilter) {
        case t('VacanciesListWithFilter.allCities'):
          return vacanciesWithButtonLabel
        case t('VacanciesListWithFilter.remoteWork'):
          return vacanciesWithButtonLabel.filter(({ isRemoteWork }) => isRemoteWork)
        default:
          return vacanciesWithButtonLabel.filter(({ cityTitle }) => cityTitle === cityFilter)
      }
    }

    return filteredByCity().filter(({ titlePortal }) => titlePortal.toLowerCase().includes(searchQuery.toLowerCase()))
  }

  return (
    <Space
      direction="vertical"
      size={15}
      style={{ width: '100%' }}
    >
      <Select
        ref={selectRef}
        clearIcon={<CloseCircleOutlined />}
        options={citySelectOptions}
        showSearch
        style={{ width: '100%' }}
        value={cityFilter}
        onChange={setCityFilter}
        onSelect={() => selectRef.current.blur()} // Прячем клавиатуру на моб. устройства при выборе
      />
      <Search
        allowClear
        placeholder={t('VacanciesListWithFilter.searchPlaceholder')}
        value={searchQuery}
        onChange={({ target }) => setSearchQuery(target.value)}
      />
      <Space
        direction="vertical"
        size={12}
        style={{ width: '100%' }}
      >
        {getFilteredVacancies().length > 0
          ? getFilteredVacancies().map(v => <Vacancy key={v.projectId} vacancy={v} />)
          : (
            <Empty
              description={t('VacanciesListWithFilter.noVacancies')}
              style={{ marginTop: 100 }}
            />
          )}
      </Space>
    </Space>

  )
}

export default VacanciesListWithFilter
