import i18next from 'i18next'

import ru from './ru.json'

i18next.init({
  resources: { ru: { translation: ru } },
  fallbackLng: 'ru',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
})
