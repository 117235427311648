import axios from 'axios'

import { env } from 'common/env'

export const request = (rawConfig) => axios(getAxiosConfig(rawConfig))
  .then(response => response)

const getAxiosConfig = ({
  url,
  params = {},
  responseType = 'json',
  method = 'POST',
}) => {
  const config = {
    baseURL: env.REACT_APP_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    url,
    method,
    responseType,
  }

  if (params instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }

  if (method === 'GET') {
    config.params = params
  } else {
    config.data = params
  }

  return config
}
