import { Image, Space, Typography } from 'antd'
import React from 'react'
import { t } from 'i18next'

import dogLogo from 'common/assets/dog-logo.svg'
import { getHrmName } from 'common/helpers'
import { env } from 'common/env'

import { PortalFooterStyle } from './PortalFooterStyle'

const { Link, Text } = Typography

const PortalFooter = () => (
  <PortalFooterStyle align="center">
    <Space
      size={8}
      style={{ cursor: 'pointer' }}
      onClick={() => window.open(env.REACT_APP_LANDING_URL)}
    >
      <Image
        preview={false}
        src={dogLogo}
        width={50}
      />
      <Space direction="vertical" size={0}>
        <Link
          level={4}
          style={{ color: 'white', fontSize: 15 }}
        >
          {getHrmName()}
        </Link>
        <Text style={{ color: 'white' }}>
          {t('PortalFooter.bestFriendOfHr')}
        </Text>
      </Space>
    </Space>
  </PortalFooterStyle>
)

export default PortalFooter
