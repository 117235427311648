import React, { useEffect, useState } from 'react'
import {
  Space, Spin, Row, BackTop,
} from 'antd'
import { ThemeProvider } from 'styled-components/macro'

import { PortalFooter, CompanyInfo, VacanciesListWithFilter } from 'components/organisms'
import { SettingsService } from 'domain/settings'
import { VacanciesService } from 'domain/vacancies'
import { theme } from 'common/theme'

const VacancyPortal = () => {
  const [settings, setSettings] = useState(null)
  const [vacancies, setVacancies] = useState(null)

  useEffect(() => {
    const companyCode = window.location.pathname.replace('/', '')

    SettingsService.getSettings(companyCode).then(setSettings)
    VacanciesService.getVacancies(companyCode).then(setVacancies)
  }, [])

  const isLoaded = settings && vacancies

  return (
    <ThemeProvider theme={{ primaryColor: (settings && settings.color) || theme.token.primaryColor }}>
      <Row
        align="center"
        style={{ minHeight: '100vh' }}
      >
        <Spin
          size="large"
          spinning={!isLoaded}
        >
          <Space
            direction="vertical"
            size={10}
            style={{ maxWidth: 600, padding: 15 }}
          >
            {settings && <CompanyInfo settings={settings} />}
            {vacancies && (
              <VacanciesListWithFilter
                companyButtonLabel={settings.settings?.buttonLabel}
                vacancies={vacancies}
              />
            )}
          </Space>
        </Spin>
        {isLoaded && <PortalFooter />}
      </Row>
      <BackTop />
    </ThemeProvider>
  )
}

export default VacancyPortal
