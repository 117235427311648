import React from 'react'
import {
  Col, Image, Row, Space, Typography,
} from 'antd'
import ReactMarkdown from 'react-markdown'

const { Text } = Typography

const CompanyInfo = ({ settings: { description, logo, company } }) => (
  <Row
    align="middle"
    gutter={8}
    justify="center"
    style={{ flexFlow: 'row', maxWidth: '400px' }}
  >
    {logo && (
      <Col flex="100px">
        <Image preview={false} src={logo} />
      </Col>
    )}
    {description && (
      <Col flex="auto">
        <Space direction="vertical" size={2}>
          <Text strong>{company.label}</Text>
          <Text style={{ whiteSpace: 'break-spaces' }}>
            <ReactMarkdown>
              {description}
            </ReactMarkdown>
          </Text>
        </Space>
      </Col>
    )}
  </Row>
)

export default CompanyInfo
