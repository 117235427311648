import { testStagingRegex } from './regex'

const { REACT_APP_IS_FOR_RU_MARKET, NODE_ENV } = process.env

const getRuMarketEnv = () => {
  const isForRuMarketExists = ['true', 'false'].includes(REACT_APP_IS_FOR_RU_MARKET)
  const { host } = window.location
  const isUrlHasHrmka = host?.includes('hrmka.ru') || host?.includes('ru.hrbt.ru')

  if (isUrlHasHrmka) {
    return true
  }

  if (isForRuMarketExists && REACT_APP_IS_FOR_RU_MARKET === 'true') {
    return true
  }

  return false
}

window.env = {}

window.env.REACT_APP_IS_FOR_RU_MARKET = getRuMarketEnv()

if (window.env.REACT_APP_IS_FOR_RU_MARKET) {
  if (NODE_ENV === 'development') {
    window.env.REACT_APP_BASE_URL = 'https://apiru.hrbt.ru'
    window.env.REACT_APP_HUB_URL = 'https://hrbt.ru'
    window.env.REACT_APP_PORTAL_URL = 'https://portalru.hrbt.ru'
    window.env.REACT_APP_CHAT_URL = 'https://chatru.hrbt.ru'
    window.env.REACT_APP_CRM_URL = 'https://crmru.hrbt.ru'
  } else {
    window.env.REACT_APP_BASE_URL = 'https://api.hrmka.ru'
    window.env.REACT_APP_HUB_URL = 'https://hmsg.me'
    window.env.REACT_APP_PORTAL_URL = 'https://portal.hrmka.ru'
    window.env.REACT_APP_CHAT_URL = 'https://chat.hrmka.ru'
    window.env.REACT_APP_CRM_URL = 'https://crm.hrmka.ru'
  }

  window.env.REACT_APP_LANDING_URL = 'https://hrmka.ru'
}

// Если находимся на тестовом стейджинге, то перезаписываем основные переменные
if (testStagingRegex.test(window.location.host)) {
  window.env.REACT_APP_BASE_URL = window.location.origin.replace('portal', 'backend')
  window.env.REACT_APP_HUB_URL = window.location.origin.replace('portal', 'message-me')
  window.env.REACT_APP_PORTAL_URL = window.location.origin
  window.env.REACT_APP_CHAT_URL = window.location.origin.replace('portal', 'chat')
  window.env.REACT_APP_CRM_URL = window.location.origin.replace('portal', 'crm')
  window.env.REACT_APP_BUILDER_URL = window.location.origin.replace('portal', 'builder')
}

export const env = { ...process.env, ...window.env }
