import { IApiResponse } from 'common/interfaces'
import { request } from 'common/api/axios'

import { IVacancy } from './vacancies.types'

export const getVacancies = (companyCode: string): Promise<IApiResponse<IVacancy[]>> => request({
  method: 'GET',
  url: `/v1/portal/${companyCode}/vacancies`,
  params: {},
})
