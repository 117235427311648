import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import ruRU from 'antd/lib/locale/ru_RU'
import { ConfigProvider } from 'antd'
import 'locale/i18n'
import './index.css'

import { appendExternalScripts } from 'common/externalServices'
import { theme } from 'common/theme'

import App from './App'

if (process.env.NODE_ENV === 'production') {
  appendExternalScripts()
}

const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(
  <ConfigProvider locale={ruRU} theme={theme}>
    <App />
  </ConfigProvider>,
)
