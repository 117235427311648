import React from 'react'
import { Button, Result } from 'antd'
import { t } from 'i18next'

import { VacancyPortal } from 'components/pages'
import { getHrmName } from 'common/helpers'
import { env } from 'common/env'

const App = () => {
  document.title = `${getHrmName()} | ${t('App.vacancyPortal')}`

  if (window.location.pathname === '/') {
    return (
      <Result
        extra={(
          <Button href={env.REACT_APP_LANDING_URL}>
            {getHrmName()}
          </Button>
        )}
        status="404"
        subTitle={t('App.noPage')}
        title="404"
      />
    )
  }

  return <VacancyPortal />
}

export default App
