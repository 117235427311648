import { IApiResponse } from 'common/interfaces'
import { request } from 'common/api/axios'

import { ISettings } from './settings.types'

export const getSettings = (companyCode: string): Promise<IApiResponse<ISettings>> => request({
  method: 'GET',
  url: `/v1/portal/${companyCode}/settings`,
  params: {},
})
