import { message } from 'antd'
import { t } from 'i18next'

import { getVacancies } from './vacancies.api'
import { IVacancy } from './vacancies.types'

class VacanciesService {
  getVacancies(companyCode: string): Promise<IVacancy[] | void> {
    return getVacancies(companyCode)
      .then(({ data }) => data.data)
      .catch(() => { message.error(t('VacanciesService.vacancyFetchFailed')) })
  }
}

export default new VacanciesService()
