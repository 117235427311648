import React, { useMemo } from 'react'
import { Space, Typography } from 'antd'
import { t } from 'i18next'

import { env } from 'common/env'

import { SpaceStyle, RespondButtonStyle } from './VacancyStyle'

const { Text, Paragraph } = Typography

const Vacancy = ({ vacancy }) => {
  const {
    titlePortal,
    isRemoteWork,
    description,
    salary,
    cityTitle,
    settings,
    projectUuid,
  } = vacancy

  const respondUrl = useMemo(() => {
    const url = `${env.REACT_APP_BASE_URL}/projects/vacancy/index`

    const params = new URLSearchParams(window.location.search)

    if (!params.get('source')) {
      params.append('source', settings.source ?? 'portal')
    }

    params.append('uuid', projectUuid)

    return `${url}?${params.toString()}`
  }, [projectUuid, settings.source])

  return (
    <SpaceStyle direction="vertical" size={10}>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <Space direction="vertical" size={2}>
          <Text strong>{titlePortal}</Text>
          {salary && <Text>{salary}</Text>}
          {isRemoteWork ? t('Vacancy.remoteWork') : `${cityTitle}`}
        </Space>
        <RespondButtonStyle
          size="small"
          type="primary"
          onClick={() => window.open(respondUrl)}
        >
          {settings.buttonLabel}
        </RespondButtonStyle>
      </Space>
      {description && (
        <Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: t('Vacancy.showFull') }}
          style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
        >
          {description}
        </Paragraph>
      )}
    </SpaceStyle>
  )
}

export default Vacancy
