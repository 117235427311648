import { message } from 'antd'
import { t } from 'i18next'
import TurndownService from 'turndown'

import { getSettings } from './settings.api'
import { ISettings } from './settings.types'

class SettingsService {
  getSettings(companyCode: string): Promise<ISettings | void> {
    return getSettings(companyCode)
      .then(({ data }) => ({
        ...data.data,
        description: new TurndownService().turndown(data.data.description || ''),
      }))
      .catch(() => { message.error(t('SettingsService.settingsFetchFailed')) })
  }
}

export default new SettingsService()
